const {
  decimal,
  minValue,
  required,
  maxValue,
} = require("vuelidate/lib/validators");

export const ACADEMIC_ADVISER_COST = {
  /* Form SCHEMA */
  schema: [
    {
      id: "studentCostPerHour",
      label: "studentCostPerHour",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "studentDiscountPerHour",
      label: "studentDiscountPerHour",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "text",
    },
  ],

  /* Form MODEL */
  model: {
    studentCostPerHour: 0,
    studentDiscountPerHour: 0,
  },

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      studentCostPerHour: { decimal },
      studentDiscountPerHour: {
        required,
        decimal,
        min: minValue(0),
        max: maxValue(1),
      },
    },
  },
};
