<template>
  <v-row>
    <v-col cols="12">
      <v-expansion-panels multiple tile flat>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h2>{{ $_t("attribute.specifications") }}</h2>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $_t("attribute.title") }}
                    </th>
                    <th class="text-left">
                      {{ $_t("attribute.files") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(course, i) in courses" :key="i">
                    <td data-label="Name">{{ course.specificationTitle }}</td>
                    <td>
                      <CourseFiles
                        readonly
                        @update="initClass"
                        :course="course"
                      >
                        <template #activator>
                          <v-btn color="primary">{{
                            $_t("attribute.files")
                          }}</v-btn>
                        </template>
                      </CourseFiles>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import CourseFiles from "@/packages/admin/components/specifications/CourseFiles";

export default {
  name: "ClassCourses",
  components: { CourseFiles },
  computed: {
    ...mapGetters({
      singleClass: "class/getClass",
    }),
    courses() {
      return this.singleClass.specifications;
    },
  },
  methods: {
    async initClass() {
      await this.$actions.getSingleClass({
        id: this.$route.params.id,
      });
    },
  },
};
</script>

<style scoped></style>
