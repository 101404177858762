<template>
  <v-row>
    <v-col cols="12">
      <v-expansion-panels multiple tile flat>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h2>{{ $_t("attribute.lessons") }}</h2>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" md="12">
                <v-row class="mb-5">
                  <v-col cols="12" md="6">
                    <v-text-field
                      hide-details
                      dense
                      outlined
                      v-model="search"
                      :label="$_t('attribute.search')"
                      @input="filter({ search })"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    v-if="['Admin', 'TeamLeader', 'Teacher'].includes(role)"
                  >
                    <v-btn
                      width="11rem"
                      height="2.5rem"
                      color="primary"
                      dark
                      @click="addLessonModal = true"
                      class="float-end"
                    >
                      <v-icon class="mr-3"
                        >mdi-book-open-page-variant-outline</v-icon
                      >
                      {{ $_t("admin.components.admin_actions.add_lesson") }}
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    v-if="['Admin', 'TeamLeader', 'Teacher'].includes(role)"
                  >
                    <v-btn
                      width="11rem"
                      height="2.5rem"
                      color="primary"
                      dark
                      @click="addSpecificationModal = true"
                      class="float-end"
                    >
                      <v-icon class="mr-3">mdi-book-outline</v-icon>
                      {{
                        $_t("admin.components.admin_actions.add_specification")
                      }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-btn
                      width="11rem"
                      height="2.5rem"
                      color="primary"
                      dark
                      @click="printLessons"
                      class="float-end"
                    >
                      {{ $_t("attribute.export_lessons") }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="d-flex flex-wrap" style="gap: 16px">
                    <ConfirmDelete
                      v-if="lessonsId.length"
                      @confirm="deleteLesson()"
                    >
                      <template v-slot:activator>
                        <v-btn outlined :color="'red'">
                          <v-icon>mdi-delete</v-icon>
                          {{ $_t("attribute.delete") }}
                        </v-btn>
                      </template>
                    </ConfirmDelete>
                    <v-btn
                      v-if="lessonsId.length"
                      outlined
                      :color="'primary'"
                      @click="changeReviewState"
                    >
                      <v-icon class="mr-3">mdi-pen</v-icon>
                      {{ $_t("attribute.doneReviews") }}
                    </v-btn>
                    <v-btn
                      v-if="lessonsId.length"
                      outlined
                      :color="'primary'"
                      @click="changeTeachState"
                    >
                      <v-icon class="mr-3">mdi-pen</v-icon>
                      {{ $_t("attribute.doneTeaching") }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          {{ $_t("attribute.lessonName") }}
                        </th>
                        <th class="text-left">{{ $_t("attribute.title") }}</th>
                        <th class="text-left">
                          {{ $_t("attribute.teachingTimeInHour") }}
                        </th>
                        <th class="text-left">
                          {{ $_t("attribute.homework") }}
                        </th>
                        <th class="text-left">
                          {{ $_t("attribute.homeworkFiles") }}
                        </th>
                        <th class="text-left">
                          {{ $_t("attribute.description") }}
                        </th>
                        <th class="text-left">
                          {{ $_t("attribute.progress_percentage") }}
                        </th>
                        <th class="text-left">
                          {{ $_t("attribute.reviewState") }}
                        </th>
                        <th class="text-left">
                          {{ $_t("attribute.teachState") }}
                        </th>
                        <th class="text-left">
                          {{ $_t("attribute.edit") }}
                        </th>
                        <th class="text-left">
                          {{ $_t("attribute.select") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(lesson, i) in filteredLessons" :key="i">
                        <td data-label="Name">{{ lesson.lessonName }}</td>
                        <td data-label="Title">{{ lesson.lessonTitle }}</td>
                        <td data-label="Teaching time">
                          {{ `${lesson.teachingLessonTimeInHour} h` }}
                        </td>
                        <td data-label="Homework">
                          <div class="d-flex flex-fill">
                            <AddNewHomeworkForStudents
                              @update="initClass"
                              :lesson="lesson"
                            >
                              <template v-slot:activator>
                                <v-btn
                                  color="primary"
                                  small
                                  outlined
                                  fab
                                  class="mr-2"
                                  ><v-icon>mdi-plus</v-icon></v-btn
                                >
                              </template>
                            </AddNewHomeworkForStudents>

                            <v-btn
                              :disabled="!passedLessons.includes(lesson.key)"
                              color="primary"
                              small
                              fab
                              @click="addHomework(lesson)"
                              ><v-icon>mdi-pencil</v-icon></v-btn
                            >
                          </div>
                        </td>
                        <td>
                          <HomeWorkFilesModal
                            v-if="lesson.homeworkFiles?.length"
                            :files="lesson.homeworkFiles"
                          >
                            <template #activator>
                              <v-btn color="primary">{{
                                $_t("attribute.files")
                              }}</v-btn>
                            </template>
                          </HomeWorkFilesModal>
                        </td>
                        <td>
                          <ShowLessonDescription
                            :description="lesson.description || ''"
                          />
                        </td>
                        <td
                          data-label="Teacher progress Percentage"
                          style="height: 95px !important"
                        >
                          <v-progress-circular
                            class="my-2"
                            :rotate="-90"
                            :size="50"
                            :width="10"
                            :value="
                              singleClass.information[lesson.key]
                                .teacherProgressPercentation
                            "
                            :color="'primary'"
                          >
                            {{
                              Math.round(
                                singleClass.information[lesson.key]
                                  .teacherProgressPercentation
                              )
                            }}
                          </v-progress-circular>
                        </td>
                        <td data-label="Review state">
                          <v-btn
                            @click="
                              showInformation(
                                {
                                  ...singleClass.information[lesson.key],
                                  classroomId: $route.params.id,
                                },
                                'review'
                              )
                            "
                            :disabled="!passedLessons.includes(lesson.key)"
                            :color="'primary'"
                          >
                            {{
                              $_t(
                                `attribute.${
                                  singleClass.information[lesson.key]
                                    .reviewedState
                                }`
                              )
                            }}
                            <v-icon class="ml-3">mdi-pencil</v-icon>
                          </v-btn>
                        </td>
                        <td data-label="Teach state">
                          <div>
                            <v-btn
                              @click="
                                showInformation(
                                  {
                                    ...singleClass.information[lesson.key],
                                    classroomId: $route.params.id,
                                  },
                                  'teach'
                                )
                              "
                              :disabled="!passedLessons.includes(lesson.key)"
                              :color="'primary'"
                            >
                              {{
                                $_t(
                                  `attribute.${
                                    singleClass.information[lesson.key]
                                      .teachState
                                  }`
                                )
                              }}
                              <v-icon class="ml-3">mdi-pencil</v-icon>
                            </v-btn>
                          </div>
                        </td>
                        <td>
                          <v-btn
                            @click="
                              showEditLesson({
                                ...lesson,
                                classroomId: $route.params.id,
                                lessonId: lesson.key,
                                description: lesson.description,
                              })
                            "
                            icon
                            :color="'primary'"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </td>
                        <td>
                          <v-checkbox
                            color="red"
                            :value="lesson.key"
                            v-model="lessonsId"
                          ></v-checkbox>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <EditLessonTeach
        :is-show-modal="isShowTeachModal"
        :info="info"
        @close="cancel"
        @update="initClass"
      />
      <!--      :readonly="
          !singleClass?.times.find((t) => t.changesConfirmedByTeacher === false)
        "-->
      <EditLessonReview
        :is-show-modal="isShowReviewModal"
        :info="info"
        @close="cancel"
        @update="initClass"
      />
      <!--       :readonly="
          !singleClass?.times.find((t) => t.changesConfirmedByTeacher === false)
        "-->
      <AddLessonToClass
        @close="cancel"
        @update="initClass"
        :is-show-modal="addLessonModal"
      />
      <AddSpecificationToClass
        @close="addSpecificationModal = false"
        @update="initClass"
        :is-show-modal="addSpecificationModal"
      />
      <EditClassLesson
        @close="cancel"
        :lesson="lesson"
        @update="initClass"
        :is-show-modal="editLessonModal"
      />
      <AddHomeworkToLesson
        :is-show-modal="homeworkModal"
        :lesson="lesson"
        @update="initClass"
        @close="homeworkModal = false"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import EditLessonTeach from "@/packages/admin/components/classRoom/details/modal/EditLessonTeach";
import EditLessonReview from "@/packages/admin/components/classRoom/details/modal/EditLessonReview";
import AddLessonToClass from "@/packages/admin/components/classRoom/details/modal/AddLessonToClass";
import EditClassLesson from "@/packages/admin/components/classRoom/details/modal/EditClassLesson";
import ShowLessonDescription from "@/packages/admin/components/lessons/ShowLessonDescription";
import AddHomeworkToLesson from "@/packages/admin/components/classRoom/details/modal/AddHomeworkToLesson";
import AddSpecificationToClass from "@/packages/admin/components/classRoom/details/modal/AddSpecificationToClass";
import ConfirmDelete from "@/components/app/ConfirmDelete";
import HomeWorkFilesModal from "@/packages/admin/components/classRoom/details/HomeWorkFilesModal";
import AddNewHomeworkForStudents from "@/packages/admin/components/classRoom/details/modal/AddNewHomeworkForStudents";

export default {
  name: "ClassLessons",
  components: {
    AddNewHomeworkForStudents,
    HomeWorkFilesModal,
    ConfirmDelete,
    AddSpecificationToClass,
    AddHomeworkToLesson,
    ShowLessonDescription,
    EditClassLesson,
    AddLessonToClass,
    EditLessonReview,
    EditLessonTeach,
  },
  watch: {
    singleClass() {
      let lessons = [];
      for (const key in this.singleClass.lessons) {
        lessons.push({ ...this.singleClass.lessons[key], key });
      }
      this.filteredLessons = lessons;
      this.search = null;
    },
    passedLessons() {
      this.filter({});
    },
  },
  computed: {
    ...mapGetters({
      singleClass: "class/getClass",
      is_loading: "loading/is_loading",
      passedLessons: "class/passedLessons",
      role: "authentication/role",
    }),
    lessons() {
      if (!this.singleClass) return [];
      let lessons = [];
      for (const key in this.singleClass.lessons) {
        lessons.push({ ...this.singleClass.lessons[key], key });
      }
      return lessons;
    },
  },
  data() {
    return {
      info: {},
      lesson: {},
      isShowTeachModal: false,
      isShowReviewModal: false,
      addLessonModal: false,
      editLessonModal: false,
      filteredLessons: [],
      search: null,
      homeworkModal: false,
      addSpecificationModal: false,
      lessonsId: [],
    };
  },
  methods: {
    showEditLesson(lesson) {
      this.lesson = lesson;
      this.editLessonModal = true;
    },
    async deleteLesson() {
      const res = await this.$actions.deleteLessonFromClass({
        classroomId: this.singleClass.classroomId,
        lessonsId: this.lessonsId,
      });
      if (res) await this.initClass();
      this.lessonsId = [];
    },
    filter({ search = null }) {
      let filteredLessons = this.lessons;
      filteredLessons = search
        ? this.lessons.filter(
            (lesson) =>
              lesson.lessonName.toLowerCase().includes(search.toLowerCase()) ||
              lesson.lessonTitle.toLowerCase().includes(search.toLowerCase()) ||
              lesson.lessonSubTitle.toLowerCase().includes(search.toLowerCase())
          )
        : filteredLessons;
      this.filteredLessons = filteredLessons;
    },
    async initPassedLessons() {
      await this.$actions.getPassedLessonsOfClass({
        classroomId: this.$route.params.id,
      });
    },
    showInformation(info, state = "review") {
      this.info = info;
      if (state === "review") this.isShowReviewModal = true;
      if (state === "teach") this.isShowTeachModal = true;
    },
    cancel() {
      this.isShowReviewModal = false;
      this.isShowTeachModal = false;
      this.addLessonModal = false;
      this.editLessonModal = false;
      this.homeworkModal = false;
    },
    async initClass() {
      await this.$actions.getSingleClass({
        id: this.$route.params.id,
      });
    },
    addHomework(lesson) {
      this.lesson = lesson;
      this.homeworkModal = true;
    },
    async changeReviewState() {
      const payload = {
        classroomId: this.$route.params.id,
        reviewStatesForEdit: [],
      };
      for (const key of this.lessonsId) {
        payload.reviewStatesForEdit.push({
          reviewState: "Done",
          informationId: this.singleClass.information[key].informationId,
        });
      }
      const res = await this.$actions.editClassReview(payload);
      if (res) {
        await this.initClass();
      }
    },
    async changeTeachState() {
      const payload = {
        classroomId: this.$route.params.id,
        teachingStatesForEdit: [],
      };
      for (const key of this.lessonsId) {
        payload.teachingStatesForEdit.push({
          teachState: "Done",
          informationId: this.singleClass.information[key].informationId,
        });
      }
      const res = await this.$actions.editClassTeach(payload);
      if (res) {
        await this.initClass();
      }
    },
    printLessons() {
      let lessons = [];
      //for (const key in this.singleClass.information) {
      for (const lessonKey in this.singleClass.lessons) {
        const info = this.singleClass.information[lessonKey];
        lessons.push({
          ...this.singleClass.lessons[lessonKey],
          ...info,
        });
      }
      //}
      console.log(lessons);
      var mywindow = window.open("", "PRINT", "height=700,width=900");
      mywindow.document.write(
        "<title>" + `${this.singleClass.teacherName}-lessons` + "</title>"
      );
      mywindow.document.write("<div class='container'></div>");
      mywindow.document.write(
        "<style>\n" +
          "table {\n" +
          "  font-family: arial, sans-serif;\n" +
          "  border-collapse: collapse;\n" +
          "  width: 100%;\n" +
          "}\n" +
          "\n" +
          "td, th {\n" +
          "  border: 1px solid #dddddd;\n" +
          "  text-align: left;\n" +
          "  padding: 8px;\n" +
          "}\n" +
          "\n" +
          "tr:nth-child(even) {\n" +
          "  background-color: #dddddd;\n" +
          "}\n" +
          "</style>"
      );
      let start = mywindow.document.querySelector(".container");
      let table = mywindow.document.createElement("table");
      let row = mywindow.document.createElement("tr");
      let th = mywindow.document.createElement("th");
      let th2 = mywindow.document.createElement("th");
      let th3 = mywindow.document.createElement("th");
      let th4 = mywindow.document.createElement("th");
      let th5 = mywindow.document.createElement("th");
      let th6 = mywindow.document.createElement("th");
      th.innerText = "lesson name";
      row.append(th);
      th2.innerText = "lesson title";
      row.append(th2);
      th3.innerText = "lesson subtitle";
      row.append(th3);
      th4.innerText = "lesson level";
      row.append(th4);
      th5.innerText = "progress percentage";
      row.append(th5);
      th6.innerText = "feedback";
      row.append(th6);
      table.appendChild(row);

      for (const lesson of lessons.flat()) {
        let row2 = mywindow.document.createElement("tr");
        let td = mywindow.document.createElement("td");
        let td2 = mywindow.document.createElement("td");
        let td3 = mywindow.document.createElement("td");
        let td4 = mywindow.document.createElement("td");
        let td5 = mywindow.document.createElement("td");
        let td6 = mywindow.document.createElement("td");
        td.innerHTML = lesson.lessonName;
        row2.appendChild(td);
        td2.innerHTML = lesson.lessonTitle;
        row2.appendChild(td2);
        td3.innerHTML = lesson.lessonSubTitle;
        row2.appendChild(td3);
        td4.innerHTML = this.$_t(`attribute.${lesson.lessonLevel}`);
        row2.appendChild(td4);
        td5.innerHTML = lesson.teacherProgressPercentation;
        row2.appendChild(td5);
        td6.innerHTML = lesson.feedback || "";
        row2.appendChild(td6);
        table.appendChild(row2);
      }

      start.appendChild(table);
      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
      return true;
    },
  },
  mounted() {
    this.filter({});
    this.initPassedLessons();
  },
};
</script>

<style scoped></style>
