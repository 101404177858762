<template>
  <v-row>
    <v-col cols="12">
      <ClassLessons />
    </v-col>
    <v-col cols="12">
      <ClassCourses />
    </v-col>
    <v-col cols="12" v-if="['Admin', 'TeamLeader'].includes(role)">
      <ClassSurvey />
    </v-col>
    <v-col
      cols="12"
      v-if="
        [
          'Admin',
          'FinancialAdmin',
          'AcademicConsultant',
          'TeamLeader',
        ].includes(role)
      "
    >
      <ClassFinance />
    </v-col>
  </v-row>
</template>

<script>
import ClassLessons from "@/packages/admin/components/classRoom/details/ClassLessons";
import ClassFinance from "@/packages/admin/components/classRoom/details/ClassFinance";
import { mapGetters } from "vuex";
import ClassCourses from "@/packages/admin/components/classRoom/details/ClassCourses";
import ClassSurvey from "@/packages/admin/components/classRoom/details/ClassSurvey";
export default {
  name: "classDetails",
  components: { ClassSurvey, ClassCourses, ClassFinance, ClassLessons },
  computed: {
    ...mapGetters({
      role: "authentication/role",
    }),
  },
};
</script>

<style scoped></style>
