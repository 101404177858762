<template>
  <v-dialog width="700px" v-model="modal" transition="dialog-bottom-transition">
    <v-card class="px-4">
      <v-toolbar class="elevation-0" color="transparent">
        <span class="text-h5 font-weight-bold">{{
          $_t("attribute.edit_teaching_state")
        }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <MainSoftForm>
        <template v-slot:body>
          <v-col cols="12" md="12">
            <MyFormGenerator
              :schema="schema.teach[teachState]"
              :validator="$v"
              :enter="submit"
            ></MyFormGenerator>
          </v-col>
        </template>
        <template v-slot:action>
          <v-col cols="12">
            <div class="d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close">
                {{ $_t("attribute.cancel") }}
              </v-btn>

              <v-btn
                color="primary"
                class="ml-4"
                @click="submit"
                :loading="is_loading"
                v-if="!readonly"
              >
                {{ $_t("attribute.edit") }}
              </v-btn>
            </div>
          </v-col>
        </template>
      </MainSoftForm>
    </v-card></v-dialog
  >
</template>

<script>
import { mapGetters } from "vuex";
import MainSoftForm from "@/components/app/form/MainSoftForm";
import MyFormGenerator from "@/components/app/form/MyFormGenerator";
import { EDIT_TASK_INFORMATION_SCHEMA } from "@/packages/admin/schema/form/EDIT_TASK_INFORMATION_SCHEMA";
const { required } = require("vuelidate/lib/validators");

export default {
  name: "EditLessonTeach",
  components: { MyFormGenerator, MainSoftForm },
  props: {
    info: {
      type: Object,
      required: true,
    },
    isShowModal: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      schema: EDIT_TASK_INFORMATION_SCHEMA.schema,
      form: EDIT_TASK_INFORMATION_SCHEMA.model,
      teachState: this.info.teachState,
    };
  },
  validations() {
    let form = {
      teachState: { required },
      reviewMoment: {},
      // teachedMoment: {},
      teachedTimeInHour: {},
      delayInTeachingTimeInHour: {},
      teachingTimeLeftInHour: {},
    };
    // if (this.teachState === "Done") {
    // form.teachedMoment.required = required;
    // form.teachedTimeInHour.required = required;
    // form.teachedTimeInHour.decimal = decimal;
    // }
    return {
      form: { ...form },
    };
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
    }),
    modal: {
      get: function () {
        return this.isShowModal;
      },
      set: function (value) {
        if (!value) this.close();
      },
    },
  },
  watch: {
    async info(val) {
      if (val) {
        console.log(val);
        this.form.teachState = this.info.teachState;
        // this.form.teachedTimeInHour = this.info.teachedTimeInHour
        //   ? this.info.teachedTimeInHour
        //   : null;
        this.form.reviewMoment = this.info.reviewMoment
          ? this.info.reviewMoment
          : null;
        // this.form.teachedMoment = this.info.teachedMoment
        //   ? this.info.teachedMoment
        //   : null;
        if (
          this.info.delayInTeachingTimeInHour ||
          this.info.delayInTeachingTimeInHour === 0
        ) {
          this.form.delayInTeachingTimeInHour = this.info
            .delayInTeachingTimeInHour
            ? this.info.delayInTeachingTimeInHour.toString() + " h"
            : this.info.delayInTeachingTimeInHour === 0
            ? 0
            : null;
          this.schema.teach.Done[
            this.getIdWithName(
              this.schema.teach.Done,
              "delayInTeachingTimeInHour"
            )
          ].show = true;
        } else {
          this.schema.teach.Done[
            this.getIdWithName(
              this.schema.teach.Done,
              "delayInTeachingTimeInHour"
            )
          ].show = false;
        }
        if (
          this.info.teachingTimeLeftInHour ||
          this.info.teachingTimeLeftInHour === 0
        ) {
          this.form.teachingTimeLeftInHour = this.info.teachingTimeLeftInHour
            ? this.info.teachingTimeLeftInHour.toString() + " h"
            : this.info.teachingTimeLeftInHour === 0
            ? 0
            : null;
          this.schema.teach.Done[
            this.getIdWithName(this.schema.teach.Done, "teachingTimeLeftInHour")
          ].show = true;
        } else {
          this.schema.teach.Done[
            this.getIdWithName(this.schema.teach.Done, "teachingTimeLeftInHour")
          ].show = false;
        }
        if (this.readonly) {
          this.schema.teach[val.teachState].forEach(
            (inp) => (inp.readonly = true)
          );
        }
      }
    },
    "form.teachState": function (value) {
      this.teachState = value;
      this.$v.$reset();
    },
  },
  methods: {
    close() {
      this.$emit("close");
      this.$v.$reset();
    },
    async submit() {
      if (!this.isFormValidate()) return;
      let payload = {
        classroomId: this.info.classroomId,
        teachingStatesForEdit: [
          {
            informationId: this.info.informationId,
            teachState: this.form.teachState,
          },
        ],

        // teachedMoment: null,
        //teachedTimeInHour: null,
      };
      //if (this.teachState === "Done") {
      // payload.teachedMoment = this.form.teachedMoment;
      //payload.teachedTimeInHour = parseFloat(this.form.teachedTimeInHour);
      //}
      try {
        const res = await this.$actions.editClassTeach(payload);
        if (res) {
          this.$emit("update");
          this.close();
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped></style>
