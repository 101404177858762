<template>
  <v-row>
    <v-col cols="12">
      <v-expansion-panels multiple tile flat>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h2>{{ $_t("attribute.survey") }}</h2>
          </v-expansion-panel-header>
          <v-expansion-panel-content v-if="times">
            <template v-for="time in times">
              <v-card
                v-if="time.survey"
                class="mb-4"
                :key="time.availableTimeId"
              >
                <v-container>
                  <v-row v-for="(survey, index) in time.survey" :key="index">
                    <v-col cols="12" class="d-flex justify-space-between">
                      <span>
                        {{
                          `${survey.studentFullName} | ${$_t(
                            "attribute.start_date"
                          )} : ${$_date(time.start * 1000)} => ${getMainTime(
                            time.start * 1000,
                            "h:mm a"
                          )} : ${getMainTime(time.end * 1000, "h:mm a")} `
                        }}
                      </span>
                    </v-col>
                    <v-col
                      cols="12"
                      v-for="s in survey.surveys"
                      :key="s.questionId"
                      class="d-flex align-center justify-space-between"
                    >
                      <span class="text-h6 font-weight-bold">
                        {{ questions[s.questionId] }}
                      </span>
                      <span>
                        <v-rating
                          v-model="s.score"
                          :length="5"
                          color="primary"
                          background-color="grey lighten-1"
                          large
                          readonly
                        >
                          <template v-slot:item="props">
                            <v-icon
                              :color="
                                props.isFilled ? 'primary' : 'grey lighten-1'
                              "
                              large
                              @click="props.click"
                            >
                              {{
                                props.isFilled
                                  ? "mdi-star-circle"
                                  : "mdi-star-circle-outline"
                              }}
                            </v-icon>
                          </template>
                        </v-rating>
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        full-width
                        outlined
                        :value="survey.moreDetails"
                        hide-details
                        readonly
                        :label="$_t('attribute.details')"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { getMainTime } from "@/tools/Utils";

export default {
  name: "ClassSurvey",
  data() {
    return {
      questions: {},
      getMainTime,
    };
  },
  computed: {
    ...mapGetters({
      singleClass: "class/getClass",
      role: "authentication/role",
    }),
    times() {
      return this.singleClass?.times || [];
    },
  },
  async mounted() {
    this.questions = await this.$actions.getSurveyQuestions();
  },
};
</script>

<style scoped></style>
