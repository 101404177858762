const { decimal, required } = require("vuelidate/lib/validators");

export const TEACHER_SALARY = {
  /* Form SCHEMA */
  schema: [
    {
      id: "teacherSalaryPerHour",
      label: "teacherSalaryPerHour",
      placeholder: "",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "text",
    },
  ],

  /* Form MODEL */
  model: {
    teacherSalaryPerHour: 0,
  },

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      teacherSalaryPerHour: { required, decimal },
    },
  },
};
