import { render, staticRenderFns } from "./ClassSurvey.vue?vue&type=template&id=3b1b9f16&scoped=true&"
import script from "./ClassSurvey.vue?vue&type=script&lang=js&"
export * from "./ClassSurvey.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b1b9f16",
  null
  
)

export default component.exports