<template>
  <v-dialog width="700px" v-model="modal" transition="dialog-bottom-transition">
    <v-card class="px-4">
      <v-toolbar class="elevation-0" color="transparent">
        <span class="text-h5 font-weight-bold">{{
          $_t("attribute.academic_adviser")
        }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <MainSoftForm>
        <template v-slot:body>
          <MyFormGenerator
            lg="10"
            :schema="schema"
            :validator="$v"
            :enter="submit"
          ></MyFormGenerator>
        </template>
        <template v-slot:action>
          <v-col cols="12">
            <div class="d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close">
                {{ $_t("attribute.cancel") }}
              </v-btn>

              <v-btn
                color="primary"
                class="ml-4"
                @click="submit"
                :loading="is_loading"
              >
                {{ $_t("attribute.edit") }}
              </v-btn>
            </div>
          </v-col>
        </template>
      </MainSoftForm>
    </v-card></v-dialog
  >
</template>

<script>
import MainSoftForm from "@/components/app/form/MainSoftForm";
import MyFormGenerator from "@/components/app/form/MyFormGenerator";
import { mapGetters } from "vuex";
import { ACADEMIC_ADVISER_COST } from "@/packages/admin/schema/classRoom/ACADEMIC_ADVISER_COST.schema";
export default {
  name: "AddStudentAdviserCost",
  components: { MyFormGenerator, MainSoftForm },
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
    student: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      schema: ACADEMIC_ADVISER_COST.schema,
      form: ACADEMIC_ADVISER_COST.model,
    };
  },
  validations: {
    ...ACADEMIC_ADVISER_COST.validations,
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
    }),
    modal: {
      get: function () {
        return this.isShowModal;
      },
      set: function (value) {
        if (!value) this.close();
      },
    },
  },
  methods: {
    async submit() {
      try {
        if (!this.isFormValidate()) return;
        console.log(this.form);
        const res = await this.$actions.addStudentAcademicCost({
          classroomId: this.$route.params.id,
          studentsCostDetail: [
            {
              studentPublicKey: this.student.studentPublicKey,
              studentCostPerHour: parseFloat(this.form.studentCostPerHour),
              studentDiscountPerHour: parseFloat(
                this.form.studentDiscountPerHour
              ),
            },
          ],
        });
        if (res) {
          this.$emit("update");
          this.close();
        }
      } catch (e) {
        console.log(e);
      }
    },
    close() {
      this.$emit("close");
      this.clear();
    },
    clear() {
      this.form = {
        studentCostPerHour: null,
        studentDiscountPerHour: null,
      };
      this.$v.$reset();
    },
  },
};
</script>
