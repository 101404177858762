<template>
  <v-dialog width="700px" v-model="modal" transition="dialog-bottom-transition">
    <v-card class="px-4">
      <v-toolbar class="elevation-0" color="transparent">
        <span class="text-h5 font-weight-bold">{{
          $_t("attribute.teaching_salary")
        }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <MainSoftForm>
        <template v-slot:body>
          <MyFormGenerator
            lg="10"
            :schema="schema"
            :validator="$v"
            :enter="submit"
          ></MyFormGenerator>
        </template>
        <template v-slot:action>
          <v-col cols="12">
            <div class="d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close">
                {{ $_t("attribute.cancel") }}
              </v-btn>

              <v-btn
                color="primary"
                class="ml-4"
                @click="submit"
                :loading="is_loading"
              >
                {{ $_t("attribute.edit") }}
              </v-btn>
            </div>
          </v-col>
        </template>
      </MainSoftForm>
    </v-card></v-dialog
  >
</template>

<script>
import MainSoftForm from "@/components/app/form/MainSoftForm";
import MyFormGenerator from "@/components/app/form/MyFormGenerator";
import { mapGetters } from "vuex";
import { TEACHER_SALARY } from "@/packages/admin/schema/classRoom/TEACHER_SALARY.schema";
export default {
  name: "AddTeacherSalary",
  components: { MyFormGenerator, MainSoftForm },
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      schema: TEACHER_SALARY.schema,
      form: TEACHER_SALARY.model,
    };
  },
  watch: {
    isShowModal(val) {
      if (!val) this.clear();
    },
  },
  validations: {
    ...TEACHER_SALARY.validations,
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
    }),
    modal: {
      get: function () {
        return this.isShowModal;
      },
      set: function (value) {
        if (!value) this.close();
      },
    },
  },
  methods: {
    async submit() {
      try {
        if (!this.isFormValidate()) return;
        console.log(this.form);
        const res = await this.$actions.addTeacherSalary({
          classroomId: this.$route.params.id,
          teacherSalaryPerHour: parseFloat(this.form.teacherSalaryPerHour),
        });
        if (res) {
          this.$emit("update");
          this.close();
        }
      } catch (e) {
        console.log(e);
      }
    },
    close() {
      this.$emit("close");
    },
    clear() {
      this.form.teacherSalaryPerHour = null;
      this.$v.$reset();
    },
  },
};
</script>
