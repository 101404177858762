<template>
  <v-dialog width="700px" v-model="modal" transition="dialog-bottom-transition">
    <v-card class="px-4">
      <v-toolbar class="elevation-0" color="transparent">
        <span class="text-h5 font-weight-bold">{{
          $_t("attribute.side_cost")
        }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-row v-for="(v, index) in $v.form.$each.$iter" :key="index">
          <FormWrapper :validator="v" style="width: 100%">
            <v-row>
              <v-col cols="12" md="6">
                <FormGroup name="subject">
                  <template v-slot="{ attrs, hasErrors }">
                    <v-text-field
                      label="Subject"
                      outlined
                      v-bind="attrs"
                      :error="hasErrors"
                      dense
                      v-model.trim="v.subject.$model"
                    />
                  </template>
                </FormGroup>
              </v-col>
              <v-col cols="12" md="6">
                <FormGroup name="fee">
                  <template v-slot="{ attrs, hasErrors }">
                    <v-text-field
                      label="Fee"
                      outlined
                      v-bind="attrs"
                      :error="hasErrors"
                      dense
                      v-model.number="v.fee.$model"
                    />
                  </template>
                </FormGroup>
              </v-col>
              <v-col cols="12" md="6">
                <FormGroup name="date">
                  <template v-slot="{ attrs, hasErrors }">
                    <V2DateTimePicker
                      :label="$_t('attribute.date')"
                      append-icon="mdi-calendar"
                      readonly
                      :attrss="attrs"
                      :hasErrors="hasErrors"
                      :error="hasErrors"
                      dense
                      v-model.trim="v.date.$model"
                    />
                  </template>
                </FormGroup>
              </v-col>
              <v-col cols="12" md="6">
                <FormGroup name="description">
                  <template v-slot="{ attrs, hasErrors }">
                    <v-textarea
                      :label="$_t('attribute.description')"
                      outlined
                      v-bind="attrs"
                      :error="hasErrors"
                      dense
                      v-model.trim="v.description.$model"
                    />
                  </template>
                </FormGroup>
              </v-col>
            </v-row>
            <v-divider class="mb-5" />
          </FormWrapper>
        </v-row>

        <v-row>
          <v-col cols="12">
            <div class="d-flex justify-end align-center">
              <v-btn
                class="primary"
                fab
                small
                @click="
                  form.push({
                    subject: null,
                    fee: null,
                    date: null,
                    description: null,
                    sideCostId: null,
                  })
                "
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn
                class="red ml-3"
                fab
                small
                dark
                v-if="form.length > 1"
                @click="form.pop()"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close">
                {{ $_t("attribute.cancel") }}
              </v-btn>

              <v-btn
                color="primary"
                class="ml-4"
                @click="submit"
                :loading="is_loading"
              >
                {{ $_t("attribute.edit") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card></v-dialog
  >
</template>

<script>
import { mapGetters } from "vuex";
import { minLength, required, decimal } from "vuelidate/lib/validators";
import {
  singleErrorExtractorMixin,
  templates,
} from "vuelidate-error-extractor";
import FormGroup from "@/components/app/form/FormGroup";
import V2DateTimePicker from "@/components/app/form/V2DateTimePicker";

export default {
  name: "AddStudentSideCostModal",
  extends: singleErrorExtractorMixin,
  components: {
    V2DateTimePicker,
    FormGroup,
    FormWrapper: templates.FormWrapper,
  },
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
    student: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: [
        {
          subject: null,
          fee: null,
          date: null,
          description: null,
          sideCostId: null,
        },
      ],
    };
  },
  validations: {
    form: {
      required,
      minLength: minLength(1),
      $each: {
        subject: { required },
        fee: {
          required,
          decimal,
        },
        date: { required },
        description: {},
        sideCostId: {},
      },
    },
  },
  watch: {
    isShowModal(val) {
      if (val) {
        console.log(this.student.studentSideCosts);
        if (this.student?.studentSideCosts) {
          this.form = [];
          this.student.studentSideCosts.forEach((c) =>
            this.form.push({ ...c })
          );
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
    }),
    modal: {
      get: function () {
        return this.isShowModal;
      },
      set: function (value) {
        if (!value) this.close();
      },
    },
  },
  methods: {
    async submit() {
      if (!this.isFormValidate()) return;
      console.log(this.form);
      const res = await this.$actions.addStudentSideCost({
        classroomId: this.$route.params.id,
        studentPublicKey: this.student.studentPublicKey,
        studentSideCosts: [...this.form],
      });
      if (res) {
        this.$emit("update");
        this.close();
      }
    },
    close() {
      this.$emit("close");
      this.clear();
    },
    clear() {
      this.form = [
        {
          subject: null,
          fee: null,
          date: null,
          description: null,
          sideCostId: null,
        },
      ];
      this.$v.$reset();
    },
  },
};
</script>

<style scoped></style>
