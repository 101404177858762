<template>
  <v-dialog width="700px" v-model="modal" transition="dialog-bottom-transition">
    <v-card class="px-4">
      <v-toolbar class="elevation-0" color="transparent">
        <span class="text-h5 font-weight-bold">{{
          $_t("admin.components.lessons_table.edit_lesson")
        }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <MainSoftForm>
        <template v-slot:body>
          <MyFormGenerator
            lg="10"
            :schema="schema"
            :validator="$v"
            :enter="submit"
          ></MyFormGenerator>
        </template>
        <template v-slot:action>
          <v-col cols="12">
            <div class="d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close">
                {{ $_t("attribute.cancel") }}
              </v-btn>

              <v-btn
                color="primary"
                class="ml-4"
                @click="submit"
                :loading="is_loading"
                v-if="!readonly"
              >
                {{ $_t("attribute.edit_exam") }}
              </v-btn>
            </div>
          </v-col>
        </template>
      </MainSoftForm>
    </v-card></v-dialog
  >
</template>

<script>
import MainSoftForm from "@/components/app/form/MainSoftForm";
import MyFormGenerator from "@/components/app/form/MyFormGenerator";
import { mapGetters } from "vuex";
import { EDIT_LESSON_CLASS } from "@/packages/admin/schema/classRoom/EDIT_LESSON_CLASS.schema";
export default {
  name: "EditClassLesson",
  components: { MyFormGenerator, MainSoftForm },
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
    lesson: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      schema: EDIT_LESSON_CLASS.schema,
      form: EDIT_LESSON_CLASS.model,
    };
  },
  validations: {
    ...EDIT_LESSON_CLASS.validations,
  },
  watch: {
    lesson(val) {
      if (val) {
        this.form.description = val.description;
        this.form.teachingTimeInHour = this.lesson.teachingLessonTimeInHour;
        if (this.readonly) {
          this.schema.forEach((inp) => (inp.readonly = true));
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
    }),
    modal: {
      get: function () {
        return this.isShowModal;
      },
      set: function (value) {
        if (!value) this.close();
      },
    },
  },
  methods: {
    async submit() {
      try {
        if (!this.isFormValidate()) return;
        const res = await this.$actions.editLessonOfClass({
          classroomId: this.$route.params.id,
          lessonId: this.lesson.lessonId,
          lessonTeachingLessonTimeInHour: parseFloat(
            this.form.teachingTimeInHour
          ),
          lessonDescription: this.form.description,
        });

        if (res) {
          this.$emit("update");
          this.close();
        }
      } catch (e) {
        console.log(e);
      }
    },
    close() {
      this.$emit("close");
      this.clear();
    },
    clear() {
      this.form = {
        teachingTimeInHour: null,
        description: null,
      };
      this.$v.$reset();
    },
  },
};
</script>

<style scoped></style>
