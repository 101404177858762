<template>
  <v-dialog width="700px" v-model="modal" transition="dialog-bottom-transition">
    <v-card class="px-4">
      <v-toolbar class="elevation-0" color="transparent">
        <span class="text-h5 font-weight-bold">{{
          $_t("admin.components.lessons_table.add_lesson")
        }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <MainSoftForm>
        <template v-slot:body>
          <v-col cols="12" class="mb-5">
            <v-select
              hide-details
              :items="lessons"
              dense
              return-object
              v-model="lesson"
              :item-text="(item) => `${item.name} | ${item.title}`"
              @input="bindLesson($event)"
              outlined
              :label="$_t('attribute.lessons')"
            >
              <template v-slot:prepend-item>
                <div class="px-3 py-2">
                  <v-text-field
                    outlined
                    :label="$_t('attribute.search')"
                    dense
                    hide-details
                    @input="initLessons"
                    v-model="searchLessons"
                  />
                </div>
              </template>
            </v-select>
          </v-col>

          <MyFormGenerator
            lg="10"
            :schema="schema"
            :validator="$v"
            :enter="submit"
          ></MyFormGenerator>
        </template>
        <template v-slot:action>
          <v-col cols="12">
            <div class="d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close">
                {{ $_t("attribute.cancel") }}
              </v-btn>

              <v-btn
                color="primary"
                class="ml-4"
                @click="submit"
                :loading="is_loading"
              >
                {{ $_t("attribute.add") }}
              </v-btn>
            </div>
          </v-col>
        </template>
      </MainSoftForm>
    </v-card></v-dialog
  >
</template>

<script>
import MainSoftForm from "@/components/app/form/MainSoftForm";
import MyFormGenerator from "@/components/app/form/MyFormGenerator";
import { mapGetters } from "vuex";
import { ADD_LESSON_TO_CLASSROOM } from "@/packages/admin/schema/classRoom/ADD_LESSON_TO_CLASSROOM.schema";
export default {
  name: "AddLessonToClass",
  components: { MyFormGenerator, MainSoftForm },
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      schema: ADD_LESSON_TO_CLASSROOM.schema,
      form: ADD_LESSON_TO_CLASSROOM.model,
      lesson: null,
      searchLessons: null,
    };
  },
  validations: {
    ...ADD_LESSON_TO_CLASSROOM.validations,
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      lessons: "admin/get_lessons",
      singleClass: "class/getClass",
    }),
    modal: {
      get: function () {
        return this.isShowModal;
      },
      set: function (value) {
        if (!value) this.close();
      },
    },
  },
  watch: {
    isShowModal(val) {
      if (val) {
        this.initLessons();
      }
    },
  },
  methods: {
    async initLessons() {
      await this.$actions.getLessons({
        all: true,
        dispatch: "admin/setLessons",
        baseType: this.singleClass.baseType,
        search: this.searchLessons,
      });
    },
    async submit() {
      try {
        if (!this.isFormValidate()) return;
        const res = await this.$actions.addLessonToClass({
          classroomId: this.$route.params.id,
          teachingTimeInHour: parseFloat(this.form.teachingTimeInHour),
          lessonLevel: this.form.level.value,
          lessonName: this.form.name,
          lessonTitle: this.form.title,
          lessonSubTitle: this.form.subtitle,
          description: this.form.description,
        });

        if (res) {
          this.$emit("update");
          this.close();
        }
      } catch (e) {
        console.log(e);
      }
    },
    close() {
      this.$emit("close");
      this.searchLessons = null;
      this.clear();
      this.form.doneMoment = null;
    },
    bindLesson(lesson) {
      console.log(lesson);
      this.form.baseType = lesson.baseType;
      this.form.description = lesson.description || null;
      this.form.name = lesson.name;
      this.form.title = lesson.title;
      this.form.subtitle = lesson.subTitle;
      this.form.level = lesson.level;
      this.form.teachingTimeInHour = lesson.teachingTimeInHour;
    },
    clear() {
      this.lesson = null;
      this.form = {
        name: null,
        title: null,
        subtitle: null,
        level: null,
        teachingTimeInHour: null,
        description: null,
        baseType: null,
      };
      this.lesson = null;
      this.$v.$reset();
    },
  },
};
</script>

<style scoped></style>
