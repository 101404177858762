const { required, decimal } = require("vuelidate/lib/validators");

export const EDIT_LESSON_CLASS = {
  /* Form SCHEMA */
  schema: [
    {
      id: "teachingTimeInHour",
      label: "teaching_time",
      placeholder: "",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "description",
      label: "description",
      placeholder: "",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "textarea",
    },
  ],

  /* Form MODEL */
  model: {
    teachingTimeInHour: null,
    description: null,
  },

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      teachingTimeInHour: { required, decimal },
      description: { required },
    },
  },
};
