import { _t } from "@/tools/Utils";

export const EDIT_TASK_INFORMATION_SCHEMA = {
  /* Form SCHEMA */
  schema: {
    review: {
      Pending: [
        {
          id: "reviewState",
          label: "reviewState",
          placeholder: "",
          show: true,
          cols: 12,
          lg: 12,
          md: 12,
          sm: 12,
          value: "value",
          type: "select",
          items: [
            { text: _t("attribute.Pending"), value: "Pending" },
            { text: _t("attribute.Done"), value: "Done" },
            { text: _t("attribute.WithOutReview"), value: "WithOutReview" },
          ],
        },
      ],
      Done: [
        {
          id: "reviewState",
          label: "reviewState",
          placeholder: "",
          show: true,
          cols: 12,
          lg: 12,
          md: 12,
          sm: 12,
          value: "value",
          type: "select",
          items: [
            { text: _t("attribute.Pending"), value: "Pending" },
            { text: _t("attribute.Done"), value: "Done" },
            { text: _t("attribute.WithOutReview"), value: "WithOutReview" },
          ],
        },
        // {
        //   id: "reviewMoment",
        //   label: "reviewMoment",
        //   placeholder: "",
        //   cols: 12,
        //   lg: 12,
        //   md: 12,
        //   sm: 12,
        //   type: "datetime",
        // },
      ],
      WithOutReview: [
        {
          id: "reviewState",
          label: "reviewState",
          placeholder: "",
          show: true,
          cols: 12,
          lg: 12,
          md: 12,
          sm: 12,
          value: "value",
          type: "select",
          items: [
            { text: _t("attribute.Pending"), value: "Pending" },
            { text: _t("attribute.Done"), value: "Done" },
            { text: _t("attribute.WithOutReview"), value: "WithOutReview" },
          ],
        },
      ],
    },
    teach: {
      Pending: [
        {
          id: "teachState",
          label: "teachState",
          placeholder: "",
          show: true,
          cols: 12,
          lg: 12,
          md: 12,
          sm: 12,
          value: "value",
          type: "select",
          items: [
            { text: _t("attribute.Pending"), value: "Pending" },
            { text: _t("attribute.Done"), value: "Done" },
            { text: _t("attribute.WithOutTeach"), value: "WithOutTeach" },
          ],
        },
      ],
      Done: [
        {
          id: "teachState",
          label: "teachState",
          placeholder: "",
          show: true,
          cols: 12,
          lg: 12,
          md: 12,
          sm: 12,
          value: "value",
          type: "select",
          items: [
            { text: _t("attribute.Pending"), value: "Pending" },
            { text: _t("attribute.Done"), value: "Done" },
            { text: _t("attribute.WithOutTeach"), value: "WithOutTeach" },
          ],
        },
        // {
        //   id: "teachedMoment",
        //   label: "teachedMoment",
        //   placeholder: "",
        //   cols: 12,
        //   lg: 12,
        //   md: 12,
        //   sm: 12,
        //   type: "datetime",
        // },
        // {
        //   id: "teachedTimeInHour",
        //   label: "teachedTimeInHour",
        //   placeholder: "",
        //   cols: 12,
        //   lg: 12,
        //   md: 12,
        //   sm: 12,
        //   type: "text",
        // },
        {
          id: "delayInTeachingTimeInHour",
          label: "delayInTeachingTimeInHour",
          placeholder: "",
          readonly: true,
          show: false,
          cols: 12,
          lg: 12,
          md: 12,
          sm: 12,
          type: "text",
        },
        {
          id: "teachingTimeLeftInHour",
          label: "teachingTimeLeftInHour",
          placeholder: "",
          readonly: true,
          show: false,
          cols: 12,
          lg: 12,
          md: 12,
          sm: 12,
          type: "text",
        },
      ],
      WithOutTeach: [
        {
          id: "teachState",
          label: "teachState",
          placeholder: "",
          show: true,
          cols: 12,
          lg: 12,
          md: 12,
          sm: 12,
          value: "value",
          type: "select",
          items: [
            { text: _t("attribute.Pending"), value: "Pending" },
            { text: _t("attribute.Done"), value: "Done" },
            { text: _t("attribute.WithOutTeach"), value: "WithOutTeach" },
          ],
        },
      ],
    },
  },

  /* Form MODEL */
  model: {
    reviewState: null,
    reviewMoment: null,
    teachState: null,
    teachedMoment: null,
    teachedTimeInHour: null,
    delayInTeachingTimeInHour: null,
    teachingTimeLeftInHour: null,
  },
};
