<template>
  <v-row v-if="singleClass">
    <v-col cols="12">
      <v-expansion-panels multiple tile flat>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h2>{{ $_t("attribute.finance") }}</h2>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col
                cols="12"
                md="6"
                v-if="['Admin', 'FinancialAdmin', 'TeamLeader'].includes(role)"
              >
                <v-card class="mx-auto" hover>
                  <v-card-text class="text--primary text-capitalize">
                    {{ singleClass.teacherName }}
                  </v-card-text>
                  <v-card-subtitle class="pb-0">
                    {{
                      `${$_t("attribute.role")}: ${$_t("attribute.Teacher")}`
                    }}
                  </v-card-subtitle>
                  <v-card-subtitle class="pb-0">
                    {{
                      `${$_t("attribute.teacherSalaryPerHour")}: ${
                        singleClass.teacherSalaryPerHour || 0
                      }`
                    }}
                  </v-card-subtitle>

                  <v-card-actions>
                    <v-btn
                      block
                      :disabled="['TeamLeader'].includes(role)"
                      color="#38BF76"
                      @click="addTeacherSalary"
                      outlined
                    >
                      {{ $_t("attribute.teaching_salary") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <template
                v-if="
                  ['Admin', 'FinancialAdmin', 'AcademicConsultant'].includes(
                    role
                  )
                "
              >
                <v-col
                  cols="12"
                  md="6"
                  v-for="(student, i) in singleClass.students"
                  :key="i"
                >
                  <v-card class="mx-auto" hover>
                    <v-card-text class="text--primary text-capitalize">
                      {{ student.studentFullName }}
                    </v-card-text>
                    <v-card-subtitle class="pb-0">
                      {{
                        `${$_t("attribute.role")}: ${$_t("attribute.Student")}`
                      }}
                    </v-card-subtitle>
                    <v-card-subtitle class="pb-0 justify-space-between d-flex">
                      <span>
                        {{
                          `${$_t("attribute.studentCostPerHour")}: ${
                            student.studentCostPerHour || 0
                          }`
                        }}
                      </span>
                      <span>
                        {{
                          `${$_t("attribute.studentDiscountPerHour")}: ${
                            student.studentDiscountPerHour || 0
                          }`
                        }}
                      </span>
                    </v-card-subtitle>

                    <v-card-actions class="justify-space-between">
                      <v-btn
                        color="#AE2CA1"
                        @click="addStudentCost(student)"
                        outlined
                      >
                        {{ $_t("attribute.academic_adviser_cost") }}
                      </v-btn>
                      <v-btn
                        color="#AE2CA1"
                        @click="addSideCost(student)"
                        outlined
                      >
                        {{ $_t("attribute.add_side_cost") }}
                      </v-btn>
                      <v-btn
                        color="primary"
                        @click="addDeposit(student)"
                        outlined
                      >
                        {{ $_t("attribute.addDeposit") }}
                      </v-btn>
                      <v-btn color="primary" dark @click="seeWallet(student)">
                        {{ $_t("attribute.wallet") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </template>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <AddStudentAdviserCost
        :is-show-modal="studentCostModal"
        :student="student"
        @close="close"
        @update="update"
      />
      <AddStudentSideCostModal
        :is-show-modal="studentSideCostModal"
        :student="student"
        @close="studentSideCostModal = false"
        @update="update"
      />
      <AddTeacherSalary
        :is-show-modal="teacherSalaryModal"
        @close="close"
        @update="update"
      />
      <AddDeposit
        :is-show-modal="addDepositModal"
        @close="addDepositModal = false"
        :user="student"
        :single-class="singleClass"
      />
      <Balances
        :is-show-modal="balanceModal"
        :balance="balance"
        @close="balanceModal = false"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import AddStudentAdviserCost from "@/packages/admin/components/classRoom/details/modal/AddStudentAdviserCost";
import AddTeacherSalary from "@/packages/admin/components/classRoom/details/modal/AddTeacherSalary";
import AddDeposit from "@/packages/finance/components/deposit/AddDeposit";
import message from "@/tools/Message";
import Balances from "@/packages/finance/components/deposit/Balances";
import AddStudentSideCostModal from "@/packages/admin/components/classRoom/details/modal/AddStudentSideCostModal";

export default {
  name: "ClassFinance",
  components: {
    AddStudentSideCostModal,
    Balances,
    AddDeposit,
    AddTeacherSalary,
    AddStudentAdviserCost,
  },
  computed: {
    ...mapGetters({
      singleClass: "class/getClass",
      is_loading: "loading/is_loading",
      user: "authentication/user",
      role: "authentication/role",
    }),
  },
  data() {
    return {
      student: {},
      studentCostModal: false,
      studentSideCostModal: false,
      teacherSalaryModal: false,
      addDepositModal: false,
      balance: null,
      balanceModal: false,
    };
  },
  methods: {
    addStudentCost(student) {
      this.student = student;
      this.studentCostModal = true;
    },
    addSideCost(student) {
      this.student = student;
      this.studentSideCostModal = true;
    },
    addTeacherSalary() {
      this.teacherSalaryModal = true;
    },
    async addDeposit(student) {
      console.log(student);
      this.student = student;
      await this.$actions.getClassroomsOfUser({
        publicKey: student.studentPublicKey,
        dispatch: "class/setClassroomsOfUser",
      });
      this.addDepositModal = true;
    },
    async seeWallet(item) {
      const res = await this.$actions.allStudentsBalance({
        dispatch: "deposit/setBalances",
        publicKey: item.studentPublicKey,
      });
      if (!res.balances?.length)
        return message.error("Student has to balances");

      this.balance = res;
      this.balanceModal = true;
    },
    close() {
      this.studentCostModal = false;
      this.teacherSalaryModal = false;
    },
    async update() {
      await this.$actions.getSingleClass({
        id: this.$route.params.id,
      });
    },
  },
};
</script>

<style scoped></style>
