<template>
  <v-dialog
    width="1200px"
    v-model="modal"
    transition="dialog-bottom-transition"
  >
    <v-card class="px-4">
      <v-toolbar class="elevation-0" color="transparent">
        <span class="text-h5 font-weight-bold">{{
          $_t("attribute.addSpecification")
        }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <MainSoftForm>
        <template v-slot:body>
          <v-col cols="12" class="mb-5">
            <v-row>
              <v-col cols="12" md="4">
                <v-select
                  hide-details
                  outlined
                  :label="$_t('attribute.specification')"
                  :items="specifications"
                  v-model="specification"
                  multiple
                  item-text="title"
                  return-object
                >
                  <template v-slot:prepend-item>
                    <div class="px-3 py-2">
                      <v-text-field
                        outlined
                        :label="$_t('attribute.search')"
                        dense
                        hide-details
                        @input="initSpecifications"
                        v-model="specificationSearch"
                      />
                    </div>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" md="8">
                <v-select
                  outlined
                  :label="$_t('attribute.add_lesson_to_course')"
                  multiple
                  :item-text="(item) => `${item.name} | ${item.subTitle}`"
                  hide-details
                  return-object
                  v-model="lessons"
                  :items="lessonsList"
                >
                  <template v-slot:selection="{ index, item }">
                    <v-chip color="primary" v-if="index < 4" class="my-2">
                      <span>{{
                        truncateString(`${item.name} | ${item.subTitle}`, 12)
                      }}</span>
                    </v-chip>
                    <span v-if="index === 4" class="grey--text text-caption">
                      (+{{ lessons.length - 4 }} others)
                    </span>
                  </template>
                  <template v-slot:prepend-item>
                    <div class="px-3 py-2">
                      <v-text-field
                        outlined
                        :label="$_t('attribute.search')"
                        dense
                        hide-details
                        v-model="lessonSearch"
                        @input="initLessons"
                      />
                    </div>
                  </template>
                </v-select>
              </v-col>
              <v-col
                cols="12"
                class="bordering"
                style="max-height: 500px; overflow-y: auto"
              >
                <v-row class="blue lighten-4">
                  <v-col cols="5" class="pl-3 blue--text">{{
                    $_t("attribute.lessonName")
                  }}</v-col>
                  <v-col cols="3" class="pl-3 blue--text">{{
                    $_t("attribute.teachHour")
                  }}</v-col>
                  <v-col cols="4" class="pl-3 blue--text">{{
                    $_t("attribute.actions")
                  }}</v-col>
                </v-row>
                <Container
                  @drop="onDrop"
                  drag-handle-selector=".column-drag-handle"
                  lock-axis="y"
                >
                  <Draggable
                    v-for="(item, index) in lessons"
                    :key="item.id"
                    class="row d-flex border-bottom"
                  >
                    <v-col cols="5" class="d-flex align-center pl-0">
                      <div class="ml-3">
                        {{ index + 1 }}
                      </div>
                      <div class="d-flex flex-column ml-5">
                        <div class="font-weight-bold">
                          {{ item.name }}
                          <!--                                  {{-->
                          <!--                                    item.description-->
                          <!--                                      ? `| ${item.description}`-->
                          <!--                                      : null-->
                          <!--                                  }}-->
                          {{
                            item.description
                              ? `| ${truncateString(item.description, 20)}`
                              : null
                          }}
                        </div>
                        <div class="text--disabled">
                          {{ item.title }} | {{ item.subTitle }} |
                          {{ $_t(`attribute.${item.level}`) }}
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="3" class="d-flex justify-center align-center">
                      <div class="d-flex justify-center align-baseline">
                        <v-text-field
                          type="number"
                          style="width: 100px"
                          dense
                          solo
                          :label="$_t('attribute.hours')"
                          :value="item.teachingTimeInHour"
                          @input="
                            $store.commit('class/updateTeachingHour', {
                              ...item,
                              teachingTimeInHour: $event,
                            })
                          "
                        />
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div class="d-flex justify-center align-baseline">
                        <span>
                          <v-btn @click="deleteLesson(index)" text fab>
                            <v-icon color="red">mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </span>
                        <span class="column-drag-handle text--disabled">
                          <v-icon>mdi-menu</v-icon>
                        </span>
                      </div>
                    </v-col>
                  </Draggable>
                </Container>
              </v-col>
            </v-row>
          </v-col>
        </template>
        <template v-slot:action>
          <v-col cols="12">
            <div class="d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close">
                {{ $_t("attribute.cancel") }}
              </v-btn>

              <v-btn
                color="primary"
                class="ml-4"
                @click="submit"
                :loading="is_loading"
              >
                {{ $_t("attribute.add") }}
              </v-btn>
            </div>
          </v-col>
        </template>
      </MainSoftForm>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { applyDrag } from "@/packages/admin/tools/helpers";
import { Container, Draggable } from "vue-dndrop";
import MainSoftForm from "@/components/app/form/MainSoftForm";
import message from "@/tools/Message";
export default {
  name: "AddSpecificationToClass",
  components: { MainSoftForm, Container, Draggable },
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      specificationSearch: "",
      lessonSearch: "",
    };
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      lessonsList: "admin/get_lessons",
      specifications: "admin/get_specifications",
      singleClass: "class/getClass",
    }),
    lessons: {
      get() {
        return this.$store.getters["class/getLessons"] || [];
      },
      set(value) {
        this.$store.commit("class/updateLessons", value);
      },
    },
    specification: {
      get() {
        return this.$store.getters["class/getSpecification"] || null;
      },
      set(value) {
        this.$store.commit("class/updateSpecification", value);
      },
    },
    modal: {
      get: function () {
        return this.isShowModal;
      },
      set: function (value) {
        if (!value) {
          this.close();
        }
      },
    },
  },
  watch: {
    specification: async function (val) {
      const lessons = val.map((s) => s.lessons).flat();
      await this.$store.dispatch("class/setLessons", lessons);
    },
    modal(val) {
      if (val) {
        this.initLessons();
        this.initSpecifications();
      } else {
        this.$store.commit("class/updateLessons", []);
        this.$store.commit("class/updateSpecification", []);
      }
    },
  },
  methods: {
    async submit() {
      try {
        const specifications = this.specification.map((s) => s.specificationId);
        const lessons = this.lessons.map((l) => {
          const {
            name,
            title,
            subTitle,
            level,
            teachingTimeInHour,
            description,
          } = l;
          return {
            lessonName: name,
            lessonTitle: title,
            lessonSubTitle: subTitle,
            lessonLevel: level,
            teachingLessonTimeInHour: teachingTimeInHour,
            description: description,
            homeworkSubject: null,
            homeworkDeadLine: null,
          };
        });
        if (!specifications.length || !lessons.length)
          return message.error("Please select some course");
        const res = await this.$actions.addSpecificationToClass({
          classroomId: this.$route.params.id,
          specificationIds: specifications,
          lessons,
        });

        if (res) {
          this.$emit("update");
          this.close();
        }
      } catch (e) {
        console.log(e);
      }
    },
    async initSpecifications() {
      await this.$actions.getSpecifications({
        all: true,
        dispatch: "admin/setSpecifications",
        baseType: this.singleClass.baseType,
        search: this.specificationSearch,
      });
    },
    async initLessons() {
      await this.$actions.getLessons({
        all: true,
        dispatch: "admin/setLessons",
        search: this.lessonSearch,
        baseType: this.singleClass.baseType,
      });
    },
    onDrop(dropResult) {
      this.lessons = applyDrag(this.lessons, dropResult);
    },
    deleteLesson(index) {
      this.$store.commit("class/deleteLesson", index);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
