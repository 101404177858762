<template>
  <v-dialog width="700px" v-model="modal" transition="dialog-bottom-transition">
    <v-card class="px-4">
      <v-toolbar class="elevation-0" color="transparent">
        <span class="text-h5 font-weight-bold">{{
          $_t("attribute.edit_review_state")
        }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <MainSoftForm>
        <template v-slot:body>
          <v-col cols="12" md="12">
            <MyFormGenerator
              :schema="schema.review[reviewState]"
              :validator="$v"
              :enter="submit"
            ></MyFormGenerator>
          </v-col>
        </template>
        <template v-slot:action>
          <v-col cols="12">
            <div class="d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close">
                {{ $_t("attribute.cancel") }}
              </v-btn>

              <v-btn
                color="primary"
                class="ml-4"
                @click="submit"
                :loading="is_loading"
                v-if="!readonly"
              >
                {{ $_t("attribute.edit") }}
              </v-btn>
            </div>
          </v-col>
        </template>
      </MainSoftForm>
    </v-card></v-dialog
  >
</template>

<script>
import { mapGetters } from "vuex";
import MainSoftForm from "@/components/app/form/MainSoftForm";
import MyFormGenerator from "@/components/app/form/MyFormGenerator";
import { EDIT_TASK_INFORMATION_SCHEMA } from "@/packages/admin/schema/form/EDIT_TASK_INFORMATION_SCHEMA";
const { required } = require("vuelidate/lib/validators");

export default {
  name: "EditLessonReview",
  components: { MyFormGenerator, MainSoftForm },
  props: {
    info: {
      type: Object,
      required: true,
    },
    isShowModal: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      schema: EDIT_TASK_INFORMATION_SCHEMA.schema,
      form: EDIT_TASK_INFORMATION_SCHEMA.model,
      reviewState: this.info.reviewedState,
    };
  },
  validations() {
    let form = {
      reviewState: { required },
      reviewMoment: {},
      teachedMoment: {},
      teachedTimeInHour: {},
      delayInTeachingTimeInHour: {},
      teachingTimeLeftInHour: {},
    };
    // if (this.reviewState === "Done") {
    //   form.reviewMoment.required = required;
    // }
    return {
      form: { ...form },
    };
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
    }),
    modal: {
      get: function () {
        return this.isShowModal;
      },
      set: function (value) {
        if (!value) this.close();
      },
    },
  },
  watch: {
    async info(val) {
      if (val) {
        this.form.reviewState = this.info.reviewedState;
        // this.form.reviewMoment = this.info.reviewMoment
        //   ? this.info.reviewMoment
        //   : null;
        if (this.readonly) {
          this.schema.review[val.reviewedState].forEach(
            (inp) => (inp.readonly = true)
          );
        }
      }
    },
    "form.reviewState": function (value) {
      this.reviewState = value;
      this.$v.$reset();
    },
  },
  methods: {
    close() {
      this.$emit("close");
      this.$v.$reset();
    },
    async submit() {
      if (!this.isFormValidate()) return;
      let payload = {
        classroomId: this.info.classroomId,
        reviewStatesForEdit: [
          {
            informationId: this.info.informationId,
            reviewState: this.form.reviewState,
          },
        ],

        // reviewMoment: null,
      };
      // if (this.reviewState === "Done") {
      //   payload.reviewMoment = this.form.reviewMoment;
      // }
      try {
        const res = await this.$actions.editClassReview(payload);
        if (res) {
          this.$emit("update");
          this.close();
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped></style>
