var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ClassLessons')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ClassCourses')],1),(['Admin', 'TeamLeader'].includes(_vm.role))?_c('v-col',{attrs:{"cols":"12"}},[_c('ClassSurvey')],1):_vm._e(),(
      [
        'Admin',
        'FinancialAdmin',
        'AcademicConsultant',
        'TeamLeader',
      ].includes(_vm.role)
    )?_c('v-col',{attrs:{"cols":"12"}},[_c('ClassFinance')],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }